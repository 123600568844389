<template>
  <div class="index-container">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
      <a-form-item label="Customer Name:">
        <a-input v-decorator="['customerName', { rules: [{ required: true, message: 'Please input your Customer Name' }] }]" />
      </a-form-item>
      <a-form-item label="Address:">
        <a-input v-decorator="['address']" />
      </a-form-item>
      <a-form-item label="Phone:">
        <a-input v-decorator="['phone', { rules: [{ required: true, message: 'Please input your Phone' }] }]" />
      </a-form-item>
      <a-form-item label="Email:">
        <a-input v-decorator="['email']" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit">
          Continue
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      form: this.$form.createForm(this, { name: 'userinfo' }),
    };
  },
  mounted() {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    userinfo && this.form.setFieldsValue(userinfo);
    localStorage.setItem('isMobile', this.$route.query.isMobile || '')
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) return
        localStorage.setItem('userinfo', JSON.stringify(values))
        this.$router.push('/scan')
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.index-container {
  padding: 50px 30px;
}
</style>